import NextErrorComponent from 'next/error';
import Bugsnag from '@bugsnag/js';

/**
 * Most of the code in this file is copied from the "official" Bugsnag example:
 * https://github.com/bugsnag/bugsnag-js/blob/next/examples/js/nextjs/pages/_error.js
 */

// NOTE: The issue mentioned below is already fixed, so this workaround is
// most likely no longer necessary. However, Bugsnag has not updated their example yet :/
const CustomErrorPage = ({ statusCode, hasGetInitialPropsRun, err }: any) => {
  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    Bugsnag.notify(err, (event) => {
      event.severity = 'error';
      event.unhandled = true;
    });
  }

  return <NextErrorComponent statusCode={statusCode} />;
};

CustomErrorPage.getInitialProps = async (contextData: any) => {
  const { req, res, err, asPath } = contextData;

  const errorInitialProps = await NextErrorComponent.getInitialProps(contextData);

  // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
  // getInitialProps has run
  // @ts-expect-error Bugsnag does not have a TypeScript example :/
  errorInitialProps.hasGetInitialPropsRun = true;

  // Handle 404 errors explicitly
  const statusCode = res?.statusCode ?? err?.statusCode;

  if (statusCode === 404) {
    console.log(`Handle 404 error on path ${asPath}`);
    return { statusCode };
  }

  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  if (err) {
    Bugsnag.notify(err, (event) => {
      event.severity = 'error';
      event.unhandled = true;
      event.request = req;
    });

    // Flushing before returning is necessary if deploying to Vercel, see
    // https://vercel.com/docs/platform/limits#streaming-responses
    // await require('@bugsnag/in-flight').flush(2000);

    return errorInitialProps;
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Bugsnag
  Bugsnag.notify(new Error(`_error.js getInitialProps missing data at path: ${asPath}`), (event) => {
    event.severity = 'error';
    event.unhandled = true;
    event.request = req;
  });

  // Flushing before returning is necessary if deploying to Vercel, see
  // https://vercel.com/docs/platform/limits#streaming-responses
  // await require('@bugsnag/in-flight').flush(2000);

  return errorInitialProps;
};

export default CustomErrorPage;
